import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../helper';
import SectionMedia from '../SectionMedia';
import ModalBoxInline from '../../../../components/ModalBoxInline';
import Carousel from '../../../Carousel';
import Image from '../../../Image';

import styles from './styles.module.css';

class ProductsLayout extends React.Component {

  constructor(props) {

    super(props);

    const align = props.section.styles.align ? props.section.styles.align : 'Left';
    const full = props.section.styles.full === true ? 'Full' : '';

    let columns;
    if (props.section && props.section.styles
      && props.section.styles.columns) ({ columns } = props.section.styles);

    this.state = {
      full,
      align,
      showModal: false,
      modalIndex: 0,
      columns,
    };

    this.createImage = this.createImage.bind(this);
    this.createContent = this.createContent.bind(this);
    this.createModalDesign2 = this.createModalDesign2.bind(this);
    this.createModalDesign3 = this.createModalDesign3.bind(this);
    this.createCarousel = this.createCarousel.bind(this);

  }

  /* eslint-enable class-methods-use-this */

  createImage(item, cIndex, eIndex, wrapperStyle, elementStyle, align) {

    let img;
    if (item) {

      img = (<SectionMedia
        id={item.id}
        mediaType="IMAGE"
        wrapperStyle={wrapperStyle}
        elementStyle={elementStyle}
        align={align}
        src={item.CDNLink}
        alt={item.alt}
        data={item}
        images={this.props.images}
        pagePathList={this.props.pagePathList}
        articlePathList={this.props.articlePathList}
        filePathList={this.props.filePathList}
      />);

    }

    return img;

  }

  /* eslint-disable class-methods-use-this */
  createCarousel(images, cardWrapper, carouselWrapper) {

    const cards = [];

    const wrapperStyle = this.props.layout === 'design3'
      ? 'productsCarouselImageWrapperSides' : 'productsCarouselImageWrapper';
    const imageStyle = this.props.layout === 'design3'
      ? 'productsCarouselImageSides' : 'productsCarouselImg';
    images.forEach((item, i) => {

      if (item && item.type === 'IMAGE') {

        const card = (
          <div key={`productsImage_card_${i}`} className={styles[cardWrapper]}>
            <div className={styles[wrapperStyle]}>
              <Image
                id={item.id}
                alt=""
                imageClass={imageStyle}
                className=""
                images={this.props.images}
              />
            </div>
          </div>
        );

        cards.push(card);

      }

      return null;

    });

    let arrowPos;
    let arrowSize = '';
    switch (this.props.layout) {

      case 'design2': {

        arrowPos = 'BottomLeft';
        arrowSize = 'Small';
        break;

      }
      case 'left': {

        arrowPos = 'TopRight';
        break;

      }
      case 'design3': {

        arrowPos = 'Sides';
        break;

      }
      default:
      // Nothing here

    }

    const carousel = (
      <div className={styles[carouselWrapper]}>
        <Carousel
          arrowsPos={arrowPos}
          arrowSize={arrowSize}
        >
          { cards }
        </Carousel>
      </div>

    );

    return carousel;

  }

  createContent(item, index, cols) {

    let style;
    if (item.styles.backgroundColor.active) {

      let { backgroundColor } = item.styles;
      if (
        item.styles.backgroundColor.solid === ''
        && item.styles.backgroundColor.gradient.from === ''
      ) backgroundColor = { solid: '#ffffff' };

      style = formColor(
        backgroundColor,
        false,
        item.styles.backgroundColor.opacity,
        undefined,
        this.props.themeData.colors,
      );

    }

    const align = `align${item.align}`;
    const design = (this.props.layout === 'left' || this.props.layout === 'design3')
      ? 'Left' : this.props.layout === 'design2' ? 'Design2' : '';

    const texts = [];
    let image;
    let imageAdded = false;

    item.data.forEach((elem, i) => {

      if (elem.type === 'GALLERY' && elem.data && elem.data.length === 1) {

        const elementStyle = this.props.layout === 'design2' ? 'imageFull' : `productsImageElement${this.props.layout === 'design3' ? 'Design3' : ''}`;
        image = (
          <div className={`${styles[`productsImageWrapper${design}`]}${this.props.layout === 'left' || this.props.layout === 'design3' ? ` ${styles.productsCard}` : ''}`}>
            { this.createImage(elem.data[0], index, i, 'productsImageWrapper', elementStyle, item.align)}
          </div>
        );

      } else if (elem.type === 'GALLERY' && elem.data && elem.data.length > 1 && !imageAdded) {

        let content;
        if (this.props.layout !== 'design2') content = this.createImage(elem.data[0], index, i, 'productsImageWrapper', `productsImageElement${this.props.layout === 'design3' ? 'Design3' : ''}`, item.align, true);
        else content = this.createCarousel(elem.data, 'productsCarouselWrapper', 'carouselWrapper');

        image = (
          <div className={`${styles[`productsImageWrapper${design}`]}${this.props.layout === 'left' || this.props.layout === 'design3' ? ` ${styles.productsCard}` : ''}`}>
            { content }
          </div>
        );
        imageAdded = true;

      } else if (elem.active && (elem.type === 'HEADINGS/HEADING-THREE' || elem.type === 'PARAGRAPH/PARAGRAPH')) {

        if (this.props.layout === 'design2') {

          let styleName;
          let data;

          if (elem.type === 'HEADINGS/HEADING-THREE') {

            styleName = `${styles.productsComponentHeading} ${styles.exceptionWeight} ${styles[align]}`;
            data = `<h3>${elem.text}</h3>`;


          } else {

            styleName = `${styles.productsComponentParagraph} ${styles[align]}`;
            data = `<span>${elem.text}</span>`;

          }

          const text = (
            <div
              key={`${this.props.section._id}_elem_${index}${i}`}
              className={styleName}
            >
              { HTMLParser(data) }
            </div>
          );

          texts.push(text);

        } else if (this.props.layout === 'design3' || this.props.layout === 'left') {

          if (i === 1 || i === 2) {

            let data;
            let styleName;

            if (elem.type === 'HEADINGS/HEADING-THREE') {

              styleName = `${styles.productsComponentHeading} ${styles.exceptionWeight} ${styles[align]}`;
              data = `<h3>${elem.text}</h3>`;

            } else {

              styleName = `${styles.productsComponentParagraph} ${styles[align]}`;
              data = `<span>${elem.text}</span>`;

            }

            const text = (
              <div
                key={`${this.props.section._id}_elem_${index}${i}_section`}
                className={styleName}
              >
                {HTMLParser(data)}
              </div>
            );

            texts.push(text);

          }

        }

      }

    });

    if (this.props.layout === 'design2') {

      return (
        <div
          key={`${this.props.section._id}_layout_${index}`}
          className={`${cols} ${styles.colEqualHeights}`}
        >
          <div
            data-layout="Products"
            className={`${styles[`productsComponentsWrapper${design}`]} ${styles.productsCardWrapperDesign2}`}
            style={style}
          >
            { image }
            <div className={styles.productsTextBlockDesign2Wrapper}>
              <div className={styles.productsTextBlockDesign2}>
                { texts }
              </div>
            </div>
          </div>
        </div>
      );

    } else if (this.props.layout === 'design3' || this.props.layout === 'left') {

      const d = this.props.layout === 'design3' ? 'Design3' : 'Left';
      const content = this.props.layout === 'design3'
        ? this.createModalDesign3(index, item)
        : this.createModalDesign2(index, item);

      const text = this.props.layout === 'design3'
        ? (
          <div className={`${styles.productsTextBlockDesign3Wrapper}`}>
            <div className={`${styles.productsTextBlockDesign3}`}>
              { texts }
            </div>
          </div>
        )
        : (
          <div className={styles.productsTextWrapper}>
            { texts }
          </div>
        );

      return (
        <div
          key={`${this.props.section._id}_layout_${index}`}
          className={`${cols} ${styles.colEqualHeights}`}
        >
          { content }
          <div
            data-layout="Products"
            className={`${styles[`productsComponentsWrapper${d}`]} ${styles.productsCardWrapper}`}
            style={style}
            onClick={() => this.setState({ showModal: true, modalIndex: index })}
          >
            { image }
            { text }
          </div>
        </div>
      );

    }

    return null;

  }

  createModalDesign2(index, item) {

    const align = `align${item.align}`;
    let image;
    const texts = [];
    let first = false;
    let imageAdded = false;
    item.data.forEach((elem, i) => {

      if (elem.type === 'GALLERY' && elem.data && elem.data.length === 1) {

        image = (
          <div className={styles.modalLeftColumn}>
            { this.createImage(elem.data[0], index, i, 'productsImageModalWrapper', 'imageFull', item.align, false)}
          </div>
        );

      } else if (elem.type === 'GALLERY' && elem.data && elem.data.length > 1 && !imageAdded) {

        image = (
          <div className={styles.modalLeftColumnDesign2}>
            {this.createCarousel(elem.data, 'productsCarouselWrapper', 'carouselWrapper')}
          </div>
        );
        imageAdded = true;

      } else if (elem.active && (elem.type === 'HEADINGS/HEADING-THREE' || elem.type === 'PARAGRAPH/PARAGRAPH')) {

        let styleName;
        let data;
        if (elem.type === 'HEADINGS/HEADING-THREE') {

          first = true;
          styleName = `${styles.productsComponentHeading} ${styles.exceptionWeight} ${styles[align]}`;
          data = `<h3>${elem.text}</h3>`;

        } else {

          styleName = `${styles.productsComponentParagraph} ${styles[align]}`;
          data = `<span>${elem.text}</span>`;

        }

        const text = (
          <div
            key={`${this.props.section._id}_elem_${index}${i}`}
            className={styleName}
          >
            { HTMLParser(data) }
          </div>
        );

        if (i === 3) texts.splice(texts.length === 1 && !first ? 0 : 1, 0, text);
        else texts.push(text);

      }

    });

    const data = (

      <div className={styles.productsModalWrapper}>
        { image }
        <div className={styles.modalRightColumn} style={!image ? { width: '100%' } : undefined}>
          <div className={styles.modalCloseButton}>
            <i
              className={`entypo icon-cancel ${styles.modalCloseIcon}`}
              onClick={() => this.setState({ showModal: false })}
            />
          </div>
          <div className={styles.modalTextWrapper}>
            { texts }
          </div>
        </div>
      </div>

    );

    if (this.state.modalIndex === index) {

      return (
        <ModalBoxInline
          isOpen={this.state.showModal}
          id="productsModal"
          onCloseClick={() => this.setState({ showModal: false })}
          data={data}
          noBox
          noHeaderOnMobile
        />
      );

    }

    return null;

  }

  createModalDesign3(index, item) {

    const align = `align${item.align}`;
    let image;
    const texts = [];
    let first = false;
    let imageAdded = false;

    item.data.forEach((elem, i) => {

      if (elem.type === 'GALLERY' && elem.data && elem.data.length === 1) {

        image = (
          <div className={styles.productsCarouselImageWrapperDesign3}>
            { this.createImage(elem.data[0], index, i, 'productsImageModalWrapper', 'productsImageModalDesign3', item.align, false)}
          </div>
        );

      } else if (elem.type === 'GALLERY' && elem.data && elem.data.length > 1 && !imageAdded) {

        image = this.createCarousel(elem.data, 'productsCarouselWrapperDesign3', 'modalCarouselWrapper');
        imageAdded = true;

      } else if (elem.active && (elem.type === 'HEADINGS/HEADING-THREE' || elem.type === 'PARAGRAPH/PARAGRAPH')) {

        let styleName;
        let data;
        if (elem.type === 'HEADINGS/HEADING-THREE') {

          first = true;
          styleName = `${styles.productsComponentHeading} ${styles.exceptionWeight} ${styles[align]}`;
          data = `<h3>${elem.text}</h3>`;

        } else {

          styleName = `${styles.productsComponentParagraph} ${styles[align]}`;
          data = `<span>${elem.text}</span>`;

        }

        const text = (
          <div
            key={`${this.props.section._id}_elem_${index}${i}`}
            className={styleName}
          >
            { HTMLParser(data) }
          </div>
        );

        if (i === 3) texts.splice(texts.length === 1 && !first ? 0 : 1, 0, text);
        else texts.push(text);

      }

    });

    const data = (
      <div className={styles.productsModalWrapperDesign3}>
        <div className={styles.modalCloseButtonDesign3}>
          <i
            className={`entypo icon-cancel ${styles.modalCloseIcon}`}
            onClick={() => this.setState({ showModal: false })}
          />
        </div>
        <div className={styles.modalTextWrapperDesign3}>
          { texts }
        </div>
        { image }
      </div>
    );

    if (this.state.modalIndex === index) {

      return (
        <ModalBoxInline
          isOpen={this.state.showModal}
          id="productsModal"
          onCloseClick={() => this.setState({ showModal: false })}
          data={data}
          noBox
          noHeaderOnMobile
        />
      );

    }

    return null;

  }


  render() {

    let cols;
    if (this.state.columns === 1) cols = 'col-12';
    else cols = `col-12 col-sm-6 col-md-${this.state.columns ? (12 / this.state.columns) : 4}`;

    const activeComponents = [];
    const top = [];
    this.props.section.data.map((item, index) => {

      if (item.active && item.type === 'COMPONENT') {

        const content = this.createContent(item, index, cols);
        activeComponents.push(content);

      } else if (
        item.active
        && (item.type === 'HEADINGS/HEADING-TWO' || item.type === 'PARAGRAPH/PARAGRAPH')
      ) {

        let styleName;
        let data;
        if (item.type === 'HEADINGS/HEADING-TWO') {

          styleName = `col-12 ${styles.productsMainHeader}`;
          data = `<h2>${item.text}</h2>`;


        } else {

          styleName = `col-12 ${styles.productsMainSubtitle}`;
          data = `<span>${item.text}</span>`;

        }

        const text = (
          <div
            key={`${this.props.section._id}_elem_${index}`}
            className={styleName}
          >
            { HTMLParser(data) }
          </div>
        );

        top.push(text);

      }

      return null;

    });

    const alignStyle = `align${this.state.align}`;
    let open;
    if (this.state.showModal) open = { zIndex: 999 };

    return (
      <div className={`container ${styles[`productsContainer${this.state.full}`]}`} style={open}>
        <div className={`row ${styles[alignStyle]}`}>
          { top }
          { activeComponents }
        </div>
      </div>
    );

  }

}

ProductsLayout.propTypes = {
  page: PropTypes.string,
  section: PropTypes.shape({}),
  layout: PropTypes.string,
};

export default ProductsLayout;
